<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO mx-3 my-2" v-else>
      <div v-if="config">
        <u>
          <h5 class="text-center mb-4" style="font-size: 15px!important;">Configuration for Calculating Course CO and PO
            Attainment</h5>

        </u>
        <div class=" p-4 bg-white rounded shadow">
          <div style="margin-left: -10px; font-size: 13px !important;"><b><u>Attainment Calculation
                Method</u></b><br>There are two methods for calculating CO attainment of a Course. Choose one of the
            methods listed below:</div>
          <b-row class="">
            <b-form-group class="col">
              <i style="font-size: 18px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod1');"
                class="fas fa-info-circle  fa-xm" animation="fade"></i>
              <b-modal class="no-print" title="Method 1 : Question-Based Targeted Attainment Method (QBTAM)"
                id="showMethod1" size="md" hide-footer centered>
                <span>Explanation: This method calculates the attainment level for each CO based on the percentage of
                  students who achieved the target percentage for individual questions mapped to that CO.</span><br>
                <h5><b><u>Steps:</u></b></h5>
                <p>
                  1. The CO mapped question-wise marks of each student for an assessment are noted.
                </p>
                <p>
                  2. The marks obtained for each question are converted to a percentage score for that question.
                </p>
                <p>
                  3. This percentage marks for each question are then compared with the target percentage of the CO to
                  which
                  the question is mapped.
                </p>
                <p>
                  4. The total number of students who have achieved the CO target for each question is obtained.
                </p>
                <p>
                  5. This total number of students who achieved the target is converted to a percentage value.
                </p>
                <p>
                  6. This percentage is compared to a threshold value, and the attainment level for each question is
                  determined.
                </p>
                <p>
                  7. The average value of the attainment level of all questions mapped to the same CO is calculated.
                </p>
                <p>
                  8. This average value becomes the attainment value for each CO for that assessment.
                </p>

                <h5><b><u>Advantages:</u></b></h5>
                <p>
                  1. Granular Analysis: By assessing attainment at the question level, educators gain detailed insights
                  into
                  student performance, allowing for targeted interventions.
                </p>
                <p>
                  2. Sensitivity to Question Difficulty: This method considers the difficulty level of individual
                  questions,
                  providing a more nuanced understanding of CO attainment.
                </p>
                <p>
                  3. Flexibility: Educators can adjust the target percentage for each CO based on the difficulty level
                  of
                  questions, allowing for more accurate assessment.
                </p>

                <h5><b><u>Disadvantages:</u></b></h5>
                <p>
                  1. Complexity: The method involves analyzing performance at the question level, which may be more
                  complex
                  compared to Method 2.
                </p>
                <p>
                  2. Subjectivity in Setting Target Percentage: Setting target percentages for individual questions
                  requires
                  careful consideration and may introduce subjectivity into the assessment process.
                </p>
                <b-row class="p-2">
                  <b-col style="text-align:left;">
                    <b-button variant="danger" class="text-white sm" size="sm"
                      @click="$bvModal.hide('showMethod1')">Close</b-button>
                  </b-col>
                </b-row>
              </b-modal>
              <label for="direct-weightage-input"><b>Method 1: </b>Question-Based Targeted Attainment Method
                (QBTAM)</label>
              <br><span>Explanation: This method calculates the attainment level for each CO based on the percentage of
                students who achieved the target percentage for individual questions mapped to that CO.</span>
            </b-form-group>
            <b-form-group class="col">
              <i style="font-size: 18px !important; color: #1ea2ed;" @click="$bvModal.show('showMethod2');"
                class="fas fa-info-circle  fa-xm"></i>
              <b-modal class="no-print" title="Method 2: CO-Targeted Average Attainment Method (CTAAM)" id="showMethod2"
                size="md" hide-footer centered>
                <span>Explanation: This method calculates the attainment level for each Course Outcome (CO) by averaging
                  the percentage marks achieved by students against the target percentage for each CO.</span>
                <h5><b><u>Steps:</u></b></h5>
                <p>
                  1. The CO mapped question-wise marks of each student for an assessment are noted.
                </p>
                <p>
                  2. The average marks for each CO are calculated from these marks.
                </p>
                <p>
                  3. The percentage marks for each CO are calculated based on the average marks.
                </p>
                <p>
                  4. The percentage marks for each CO are then compared with the target percentage for each CO.
                </p>
                <p>
                  5. The total number of students who have achieved the target for each CO is obtained.
                </p>
                <p>
                  6. From this, the percentage of students who achieved the target for each CO is calculated.
                </p>
                <p>
                  7. This percentage is compared to a threshold value, and the attainment level for each CO is
                  determined.
                </p>
                <h5><b><u>Advantages:</u></b></h5>
                <p>
                  1. Simplicity: The method is straightforward and easy to understand, making it accessible to
                  educators.
                </p>
                <p>
                  2. Focus on Overall CO Performance: By averaging the performance of students across all questions
                  mapped
                  to a particular CO, this method provides a holistic view of CO attainment.
                </p>
                <p>
                  3. Clear Comparison to Target: By comparing the average percentage marks for each CO with the target
                  percentage, educators can easily assess whether the COs are being met.
                </p>
                <h5><b><u>Disadvantages:</u></b></h5>
                <p>
                  1. Potential Oversimplification: Averaging the marks may oversimplify the assessment, potentially
                  masking
                  variations in student performance across different questions.
                </p>
                <p>
                  2. Lack of Granularity: This method does not provide detailed insights into student performance at the
                  question level, which may limit the ability to identify specific areas for improvement.
                </p>
                <p>
                  3. Ignoring Question Difficulty: It does not take into account the difficulty level of individual
                  questions, which may impact the overall CO attainment assessment.
                </p>

                <b-row class="p-2">
                  <b-col style="text-align:left;">
                    <b-button variant="danger" class="text-white sm" size="sm"
                      @click="$bvModal.hide('showMethod2')">Close</b-button>
                  </b-col>

                </b-row>
              </b-modal>
              <label for="indirect-weightage-input"><b>Method 2: </b>CO-Targeted Average Attainment Method
                (CTAAM)</label>
              <br><span>Explanation: This method calculates the attainment level for each Course Outcome (CO) by
                averaging
                the percentage marks achieved by students against the target percentage for each CO.</span>
            </b-form-group>
          </b-row>
          <b-row>
            <b-col md="12">
              <div v-if="config">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        Assignment
                        <b-form-group label="Choose Equation Type:">
                          <div class="d-flex">
                            <b-form-radio :value="1" v-model="selectedEquationType['assignment']">Method
                              1</b-form-radio>
                            <b-form-radio :value="2" v-model="selectedEquationType['assignment']" class="ml-3">Method
                              2</b-form-radio>
                          </div>
                        </b-form-group>
                      </th>
                      <th>
                        Assessment
                        <b-form-group label="Choose Equation Type:">
                          <div class="d-flex">
                            <b-form-radio :value="1" v-model="selectedEquationType['assessment']">Method
                              1</b-form-radio>
                            <b-form-radio :value="2" v-model="selectedEquationType['assessment']" class="ml-3">Method
                              2</b-form-radio>
                          </div>
                        </b-form-group>

                      </th>
                      <th>
                        University Exam
                        <b-form-group label="Choose Equation Type:">
                          <div class="d-flex">
                            <b-form-radio :value="1" v-model="selectedEquationType['boardexam']">Method 1</b-form-radio>
                            <b-form-radio :value="2" v-model="selectedEquationType['boardexam']" class="ml-3">Method
                              2</b-form-radio>
                          </div>
                        </b-form-group>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="  mt-4  p-4 bg-white rounded shadow" v-if="selectedEquationType['assignment']==2 || 
        selectedEquationType['assessment']==2 || selectedEquationType['boardexam']==2">
          <div class="" style="margin-left: -10px; font-size: 13px !important;"><b><u>Equation For Calculating
                Average</u></b> <br>Choose the equation for calculating
            percentage score obtained by a student for each CO:</div>
          <b-row class="mt-2">
            <b-col>
              <b-form-group label="Calculate using  :">
                <div>
                  <b-form-radio :value="true" class="ml-3"
                    v-model="activity_student_percentage_simple_average_calculation">Simple average equation
                  </b-form-radio>
                  <b-form-radio class="ml-3" :value="false"
                    v-model="activity_student_percentage_simple_average_calculation">Weighted average equation
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <div class="" v-if="activity_student_percentage_simple_average_calculation">
                <p>Equation used for
                  % of student in CO1 = SUM (Score of student in questions linked to CO1 ) / SUM (Maximum mark of
                  questions linked to CO1 )</p>
              </div>
              <div v-else>
                <p>Equation used for
                  % of student in CO1 = SUM (% score of student in question linked to CO1 ] / number of questions
                  linked to CO1</p>

                <h5>OR</h5>
                <p>
                  % of student in CO1 = [ (score of student in question1 linked to CO1/ maximum mark of question1) +
                  (score of student in question2 linked to CO1/ maximum mark of question2)+ ... ]/number of questions
                  linked to CO1</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="  mt-4 p-4 bg-white rounded shadow">
          <div class="mb-2" style="margin-left: -10px; font-size: 13px !important;"><b><u>Set Target Value</u></b>
            <br>Set the target percentage value for
            CO for all CO direct assessment tools:
          </div>

          <!-- need a table vith initially same target for all tools and then same target for all co -->
          <div>
            <b-form-checkbox @change="targetPercentageInputChanges()"
              v-model="co_target_percentages.same_target_for_all_tools">Apply same target percentage for
              all direct assessment tools (Assessment, Assignment, Laboratory, University Exam)</b-form-checkbox>
          </div>
          <div v-if="!co_target_percentages.same_target_for_all_tools">
            <b-form-checkbox @change="targetPercentageInputChanges()"
              v-model="co_target_percentages.same_target_for_all_cos">Apply same target percentage for
              all COs on every tools</b-form-checkbox>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th class="p-1">COs/Tools</th>
                  <th class="p-1">Assignment</th>
                  <th class="p-1">Assessment</th>
                  <th class="p-1">Laboratory</th>
                  <th class="p-1">University Exam</th> 
                </tr>
              </thead>
              <tr v-for="(co, cindex) in course_outcomes" :key="cindex">
                <td>{{ co.code }}</td>
                <td class="text-center" style="width: 80px;"
                  v-for="(tool, tindex) in ['assignment', 'assessment', 'laboratory' , 'boardexam']" :key="tindex">
                  <b-form-input class="p-0" @change="targetPercentageInputChanges()"
                    v-if="targetPercentageInputIsEnabled(cindex, tindex)" v-model="co_target_percentages[tool][co.id]"
                    type="number"></b-form-input>
                  <span v-else> {{ co_target_percentages[tool][co.id] }}</span>
                </td>
              </tr>
            </table>
          </div>

        </div>

        <div class="  mt-4  p-4 bg-white rounded shadow">
          <div class=" mb-2" style="margin-left: -10px; font-size: 13px !important;"><b><u>Set Threshold
                Value</u></b><br>Set the threshold values to be
            applied for all direct assessment tools for calculating attainment level:</div>
          <div class="mb-4">
            <b-form-checkbox v-model="attainmentThresholds.equal_thresholds"
              @change="changeInAttainmentToolsEqualThresholds">Apply same threshold for all direct assessment tools(Assignment, Assessment, Laboratory, University Exam)</b-form-checkbox>
          </div>
          <div v-if="attainmentThresholds.equal_thresholds">
            <!-- same thresholds  -->
            <div>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assessment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;">If</span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;">% of students achieved the target, then the
                      attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assessment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assessment')"> Add
                      Level</b-link></b-row></li>
              </ul>

            </div>



          </div>
          <div v-else>
            <div>
              <h5 style=" font-weight: bold;">Assignment</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assignment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;">If </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;">% of students achieved the target, then the
                      attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assignment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assignment')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>


            <div>
              <h5 style=" font-weight: bold;">Assessment</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.assessment"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;">If </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;">% of students achieved the target, then the
                      attainment level is</span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.assessment.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('assessment')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>
            <div>
              <h5 style=" font-weight: bold;">Laboratory</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.laboratory"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;">If </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;">% of students achieved the target, then the
                      attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.laboratory.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('laboratory')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>

            <div>
              <h5 style="font-weight: bold;">University Exam</h5>
              <ul>
                <li class="" v-for="(attainment, attainmentIndex) in attainmentThresholds.boardexam"
                  :key="attainmentIndex">
                  <b-row class="mx-2 my-1">
                    <span class="mr-1" style="align-self: center;">If </span>
                    <b-form-select class="mr-1" style="width: 60px;" :options="operators"
                      v-model="attainment.comparisan"></b-form-select>
                    <b-form-input class="mr-1" style="width: 60px;" @keypress="handleNumber"
                      v-model="attainment.compare_by"></b-form-input>
                    <span class="mr-1" style="align-self: center;">% of students achieved the target, then the
                      attainment level is </span>
                    <b-form-input class="mr-4" style="width: 60px;" v-model.number="attainment.value"
                      @keypress="handleNumber"></b-form-input>
                    <span style="align-self: center;" class="btn p-0 m-0 text-danger"
                      @click="attainmentThresholds.boardexam.splice(attainmentIndex, 1)">
                      <i class="fas fa-minus-circle" style="font-size:14px !important;"></i>
                    </span>
                  </b-row>
                </li>
                <li> <b-row class="mx-2 my-1"> <b-link @click="addValue('boardexam')"> Add
                      Level</b-link></b-row></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="  mt-4  p-4 bg-white rounded shadow">
          <div style="margin-left: -10px; font-size: 13px !important;"><b><u> Direct Split </u></b> <br>Input the
            percentage weightage split for all CO
            direct assessment tools:</div>
          <!--<div class="">
            <b-form-checkbox v-model="assesmenttools_weightage.equal_weigtage">
              Apply equal weightage for Assignment, Assessment, University Exam, Laboratory</b-form-checkbox>
          </div>-->
          <br>
          <table v-if="!assesmenttools_weightage.equal_weigtage">
            <thead>
              <th>
                Continuous Internal Evaluation
              </th>
              <th>
                Semester End Examination
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b-row>
                    <b-col>
                      <div class="">
                        Assignment Weightage
                        <b-form-input v-model="assesmenttools_weightage.assignment" @keypress="handleNumber"
                          @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="">
                        Assessment Weightage
                        <b-form-input v-model="assesmenttools_weightage.assessment" @keypress="handleNumber"
                          @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="">
                        Laboratory Weightage
                        <b-form-input v-model="assesmenttools_weightage.laboratory" @keypress="handleNumber"
                          @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
                      </div>
                    </b-col>
                  </b-row>
                </td>
                <td>
                  <b-row>
                    <b-col>
                      <div class="">
                        University Exam Weightage
                        <b-form-input v-model="assesmenttools_weightage.boardexam" @keypress="handleNumber"
                          @blur="checkWeigtageofAssessmentTools" type="number"></b-form-input>
                      </div>
                    </b-col>
                  </b-row>
                </td>
              </tr>
              <tr>
                <td>
                  CIE(Continuous Internal Evaluation):{{
                  Number(assesmenttools_weightage.assessment) + Number(assesmenttools_weightage.assignment) +
                  Number(assesmenttools_weightage.laboratory)
                  }}
                </td>
                <td>
                  SEE(Semester End Examination): {{ assesmenttools_weightage.boardexam }}
                </td>
              </tr>
            </tbody>
          </table>
          <span style="color:red" v-if="proceednext">The sum of weightage should be equal to 100</span><br>
          <table v-if="!assesmenttools_weightage.equal_weigtage">
            <tbody>
              <tr>
                <td>
                  Percentage Weightage Contribution of Assignment towards CIE:
                </td>
                <td>
                  {{ ((Number(assesmenttools_weightage.assignment?assesmenttools_weightage.assignment:0)
                  / (Number(assesmenttools_weightage.assignment?assesmenttools_weightage.assignment:0)
                  +Number(assesmenttools_weightage.assessment?assesmenttools_weightage.assessment:0)
                  +Number(assesmenttools_weightage.laboratory?assesmenttools_weightage.laboratory:0))) * 100).toFixed(2)
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Percentage Weightage Contribution of Assessment towards CIE:
                </td>
                <td>
                  {{ ((Number(assesmenttools_weightage.assessment) /
                  (Number(assesmenttools_weightage.assignment?assesmenttools_weightage.assignment:0)
                  +Number(assesmenttools_weightage.assessment?assesmenttools_weightage.assessment:0)
                  +Number(assesmenttools_weightage.laboratory?assesmenttools_weightage.laboratory:0))) * 100).toFixed(2)
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  Percentage Weightage Contribution of Laboratory towards CIE:
                </td>
                <td>
                  {{ ((Number(assesmenttools_weightage.laboratory)
                  /(Number(assesmenttools_weightage.assignment?assesmenttools_weightage.assignment:0)
                  +Number(assesmenttools_weightage.assessment?assesmenttools_weightage.assessment:0)
                  +Number(assesmenttools_weightage.laboratory?assesmenttools_weightage.laboratory:0))) * 100).toFixed(2)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4  p-4 bg-white rounded shadow">
          <div style="margin-left: -10px; font-size: 13px !important;"><b><u>Final Split </u></b><br>Input the
            percentage weightage split for direct and indirect CO assessment tools to calculate the course CO
            attainment:</div>
          <b-row class="">
            <b-form-group class="col">
              <label for="direct-weightage-input">Direct Weightage:</label>
              <b-form-input v-model="directWeightage" style="width: 70px" @keypress="handleNumber"
                @blur="checkWeigtage('direct')" id="direct-weightage-input"></b-form-input>
            </b-form-group>
            <b-form-group class="col">
              <label for="indirect-weightage-input">Indirect Weightage:</label>
              <b-form-input v-model="indirectWeightage" @keypress="handleNumber" @blur="checkWeigtage('indirect')"
                style="width: 70px" id="indirect-weightage-input"></b-form-input>
            </b-form-group>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      equationTypes: [],
      config: true,
      selectAll: true,
      selectedEquationType: {
        assessment: null,
        assignment: null,
        boardexam: null
      },
      attainmentThresholds: {
        equal_thresholds: true,
        assessment: [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ],
        assignment: [],
        boardexam: [],
        laboratory: [],
      },
      operators: [
        { value: null, text: "Select Operator" },
        { value: "<", text: "<" },
        { value: ">", text: ">" },
        { value: "<=", text: "<=" },
        { value: ">=", text: ">=" },
      ],
      directWeightage: null,
      indirectWeightage: null,
      proceednext:false,
      assesmenttools_weightage: {
        equal_weigtage: false,
        assessment: 35,
        assignment: 25,
        boardexam: 40,
        laboratory: 0,
      },
      activity_student_percentage_simple_average_calculation: true,

      co_target_percentages: {
        same_target_for_all_tools: true,
        same_target_for_all_cos: true,
        assessment: {},
        assignment: {},
        boardexam: {},
        laboratory: {},
      },
      course_outcomes: [],
    };
  },
  methods: {
    targetPercentageInputChanges() {
          let firstCoId = this.course_outcomes[0].id;
          if (this.co_target_percentages.same_target_for_all_tools) {
            let sameTarget = this.co_target_percentages.assignment[firstCoId];
            ['assignment','assessment','laboratory','boardexam'].forEach(tool => {
              this.course_outcomes.forEach(co => {
                this.co_target_percentages[tool][co.id] = sameTarget;
              });
            });
          } else if (this.co_target_percentages.same_target_for_all_cos) {
            let sameTargets = { assignment: this.co_target_percentages.assignment[firstCoId], assessment: this.co_target_percentages.assessment[firstCoId], laboratory: this.co_target_percentages.laboratory[firstCoId], boardexam: this.co_target_percentages.boardexam[firstCoId] };
            ['assignment', 'assessment', 'boardexam', 'laboratory'].forEach(tool => {
              this.course_outcomes.forEach(co => {

                this.co_target_percentages[tool][co.id] = sameTargets[tool];
              });
            });
          } else {
            return;
          }
        },
    targetPercentageInputIsEnabled(cIndex, tIndex) {
      if (this.co_target_percentages.same_target_for_all_tools)
        return (cIndex == 0 && tIndex == 0);
      if (this.co_target_percentages.same_target_for_all_cos)
        return cIndex == 0; //first 
      return true;
    },
    changeInAttainmentToolsEqualThresholds() {
      // const assssmentthrashods = [
      //     { value: 1, comparisan: '>=', compare_by: 50 },
      //     { value: 2, comparisan: '>', compare_by: 65 },
      //     { value: 3, comparisan: '>', compare_by: 80 },
      //   ];
      if (!this.attainmentThresholds.equal_thresholds) {
        this.attainmentThresholds.assignment = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
        this.attainmentThresholds.boardexam = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
        this.attainmentThresholds.laboratory = [
          { value: 1, comparisan: '>=', compare_by: 50 },
          { value: 2, comparisan: '>', compare_by: 65 },
          { value: 3, comparisan: '>', compare_by: 80 },
        ];
      } else {
        this.attainmentThresholds.assignment = [];
        this.attainmentThresholds.boardexam = [];
        this.attainmentThresholds.laboratory = [];
      }
    },
    checkWeigtage(input) {
      if (Number(this.directWeightage) + Number(this.indirectWeightage) != 100) {
        if (Number(this.directWeightage) > 100 || Number(this.indirectWeightage) > 100) {
          this.$toast.error(
            "The sum of direct indirect weightage should be equal to 100",
            {
              position: "top",
              durartion: 3000,
            }
          );
          this.directWeightage = 0;
          this.indirectWeightage = 0;
          return;
        }
        if (input == 'direct') {
          this.indirectWeightage = 100 - Number(this.directWeightage);
        } else {
          this.directWeightage = 100 - Number(this.indirectWeightage);
        }
      }
    },

    checkWeigtageofAssessmentTools() {
      this.proceednext = false;
      if (
        (Number(this.assesmenttools_weightage.assessment) +
          Number(this.assesmenttools_weightage.assignment) +
          Number(this.assesmenttools_weightage.boardexam) +
          Number(this.assesmenttools_weightage.laboratory) !=
          100)
      ) {
        this.proceednext = true;
      }
    },
    addValue(tool) {
      this.attainmentThresholds[tool].push({
        value: "",
        comparisan: null,
        compare_by: "",
      });
    },
    handleNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, { course: this.courseReport.course_id, step: 'configure' })
        .then((response) => {
          this.selectedEquationType.assessment = response.data.assessment_equation_type;
          this.selectedEquationType.assignment = response.data.assignment_equation_type;
          this.selectedEquationType.boardexam = response.data.boardexam_equation_type;
          this.attainmentThresholds = response.data.direct_tools_attainment_thresholds;
          this.directWeightage = response.data.directmethod_weigtage;
          this.indirectWeightage = response.data.indirectmethod_weigtage;
          this.assesmenttools_weightage = response.data.assesmenttools_weightage;
          this.activity_student_percentage_simple_average_calculation = response.data.activity_student_percentage_simple_average_calculation;
          this.co_target_percentages = response.data.co_target_percentages;
          for (const coId in response.data.course_outcomes) {
            const element = response.data.course_outcomes[coId];
            this.course_outcomes.push({ id: element.id, code: element.code });
          }
        });
    },
    async setAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: 'configure',
          direct_tools_attainment_thresholds: this.attainmentThresholds,
          directmethod_weigtage: this.directWeightage,
          indirectmethod_weigtage: this.indirectWeightage,
          assesmenttools_weightage: this.assesmenttools_weightage,
          activity_student_percentage_simple_average_calculation: this.activity_student_percentage_simple_average_calculation,
          co_target_percentages: this.co_target_percentages,
          selectedEquationType: this.selectedEquationType,
        })
        .then(() => { });
    },
    // async getAttainmentCourseOutcomes() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
    //   await this.$axios
    //     .post(url, { course: this.courseReport.course_id, step: "attainment" })
    //     .then(() => { });
    // },
  },
};
</script>
